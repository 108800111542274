<div class="lib-fill lib-column lib-horizontal-center lib-vertical-center">
  <h1>Page Not Found/Unauthorized Page</h1>
  <span>Please use the menu on the left to navigate to the desired page</span>
  @if (url) {
    <span>
      <strong>{{ url }}</strong> is either unauthorized, does not exist or has no
      data
    </span>
  }
</div>
