import { Component, Input } from '@angular/core';

@Component({
  selector: 'no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
  standalone: true,
  imports: [],
})
export class NoAccessComponent {
  @Input() url!: string;
}
